import React from "react";
import { graphql } from "gatsby";
import { Link, navigate } from "gatsby-plugin-intl";
import { makeStyles } from "@material-ui/core/styles";
import { Grid } from "@material-ui/core";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { MainJumbotron } from "../components/jumbotron";
import PrCard from "../components/prCard";
import ContainerBox from "../components/containerBox";
import Pagination from "../components/pagintaion";

const useStyles = makeStyles(theme => ({
  container: {
    paddingTop: "160px",
    paddingBottom: "160px",
    [theme.breakpoints.down('md')]: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  timeLineYear: {
    paddingBottom: "30px",
  },
  postLink: {
    textDecoration: "none",
  },
  pagination: {
    marginTop: 0,
  },
}));

interface DataType {
  node: {
    id: string;
    created_at: string;
    feature_image: string;
    title: string;
    ghostId: string
  }
}

interface NewsListProps {
  data: {
    allGhostPost: {
      edges: Array<DataType>;
    }
  };
  pageContext: {
    currentPage: number;
    numPages: number;
    language: string;
  };
  node: DataType["node"];
}
function NewsList({ data, pageContext }: NewsListProps) {
  const classes = useStyles();
  const {
    allGhostPost: { edges },
  } = data;
  const { currentPage, numPages, language } = pageContext;
  const handleChange = (event: React.ChangeEvent<unknown>, value: number) => {
    if (value === 1) {
      navigate("/company/news");
      return;
    }
    navigate(`/company/news/page/${value}`);
  };

  return (
    <Layout language={language}>
      <SEO title="PR" />
      <MainJumbotron />
      <ContainerBox className={classes.container}>
        <Grid
          component="section"
          container
          className={classes.timeLineYear}
          spacing={4}
        >
          {edges.map(item => (
            <Grid key={item.node.id} item lg={6} xs={12}>
              <Link
                to={`/company/news/${item.node.ghostId}`}
                className={classes.postLink}
              >
                <PrCard
                  title={item.node.title}
                  image={item.node.feature_image}
                  createdAt={item.node.created_at}
                />
              </Link>
            </Grid>
          ))}
        </Grid>
        <Pagination
          count={numPages}
          currentPage={currentPage}
          onChange={handleChange}
          className={classes.pagination}
        />
      </ContainerBox>
    </Layout>
  );
};

export default NewsList;

export const query = graphql`
  query newsListQuery($skip: Int!, $limit: Int!) {
    allGhostPost(
      sort: { order: DESC, fields: published_at }
      filter: { tags: { elemMatch: { name: { eq: "PRESS" } } } }
      limit: $limit
      skip: $skip
    ) {
      edges {
        node {
          id
          created_at
          feature_image
          title
          ghostId
        }
      }
    }
  }
`;
